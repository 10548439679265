module.exports = [{
      plugin: require('/Users/franc/projs/blog2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137364866-1"},
    },{
      plugin: require('/Users/franc/projs/blog2/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://franc.dev"},
    },{
      plugin: require('/Users/franc/projs/blog2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/franc/projs/blog2/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/franc/projs/blog2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
